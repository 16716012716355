import { Link } from 'gatsby';
import * as React from 'react';

const SuccessCTA = () => (
  <article className="w-full max-w-5xl mx-auto my-auto">
    <p className="text-4xl font-semibold leading-none uppercase font-display text-brand-blue">
      <span>
      Thank you for choosing Frontline Removals<br/>and submitting your contact details<br/>for your 
      </span>
      <span className="text-brand-orange">{' '}quote!</span>
    </p>
    <div className="max-w-xs mt-6">
      <h2 className="text-xl font-semibold capitalize">
        We've received your message
      </h2><br/>
      <p>
      Thanks for filling out our quick estimate form! Our team will be in touch soon with your quote, so please keep an eye out in your inbox for this. We will call to confirm your dates and move, talk soon!
      </p>

      <p><br/>
        Please, if you cannot see the email in your inbox, check your <strong>SPAM</strong> or
        <strong>{' '}JUNK</strong> folder; our team is working on a fix for this!
      </p>
    </div>
    {/* <div className="flex mt-6 md:relative">
      <div className="flex items-center w-full">
        <div className="flex flex-1 w-full p-8 md:max-w-xl bg-brand-blue md:rounded-r-md">
          <div className="max-w-md space-y-8">
            <h2 className="px-2 -mx-2 text-5xl leading-none text-white uppercase rounded-md bg-brand-orange md:pl-24 font-display">
              Are You Moving?
            </h2>
            <p className="text-lg text-white">
              Find out how we can help with all your removal needs to make the
              process as simple and stress-free as possible.
            </p>
            <p className="">
              <Link
                to="/furniture-removal/#moving-page-top-form"
                className="button"
              >
                Find Out More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div> */}
  </article>
);

export default SuccessCTA;
