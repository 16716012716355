import * as React from 'react';
import Helmet from 'react-helmet';

import { Hero, Layout, SEO } from '..';
import CTA from './success-cta';
import HeroImage from './success-hero-image';

const SuccessPage = () => {
  const isBrowser = typeof window !== 'undefined';

  const unformatPhone = (phone) => phone.replace(/\D/g, '');

  const getSHA256Hash = async (input) => {
    const textAsBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest(
      'SHA-256',
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
  };

  async function conversionsAPI(fname, lname, ph, em, eventID) {
    const cookies = document.cookie.split(';');
    let fbp = 'none';
    let fbc = 'none';

    cookies.map((cookie) => {
      if (cookie.includes('_fbp=')) {
        fbp = cookie.slice(cookie.indexOf('_fbp=') + 5);
        console.log(fbp);
      }
    });
    cookies.map((cookie) => {
      if (cookie.includes('_fbc=')) {
        fbc = cookie.slice(cookie.indexOf('_fbc=') + 5);
        console.log(fbc);
      }
    });

    if (fbc === 'none' && window.location.search.includes('fbclid=')) {
      const params = new URL(document.location).searchParams;
      fbc = `fb.1.${Date.now()}.${params.get('fbclid')}`;
    }
    try {
      const firstName = await getSHA256Hash(fname);
      const lastName = await getSHA256Hash(lname);
      const phone = await getSHA256Hash(ph);
      const email = await getSHA256Hash(em);
      const res = await fetch('/.netlify/functions/conversions-api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventType: 'Lead',
          fbp,
          firstName,
          lastName,
          phone,
          email,
          fbc,
          eventID,
        }),
      });
      const result = await res.json();
      console.log('Return from netlify functions conversionsAPI =', result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  React.useEffect(() => {
    if (isBrowser) {
      const params = new URL(document.location).searchParams;
      const fnameparam = params.get('firstName')?.toLowerCase();
      const lnameparam = params.get('lastName')?.toLowerCase();
      const phoneparam = unformatPhone(params.get('phone'));
      const emailparam = params.get('email')?.toLowerCase();

      const eventID = crypto.randomUUID();
      if (window.fbq)
        window.fbq('track', 'Lead', {}, { eventID });

      const fireLead = conversionsAPI(
        fnameparam,
        lnameparam,
        phoneparam,
        emailparam,
        eventID,
      );
    }
  }, []);
  
  return (
    <Layout isSuccess hideForm>
      <SEO
        title="Success | Frontline Removals"
        description="Frontline Removals: Port Macquarie’s trusted, experienced shipping and storage provider. We’ve been around for yonks. Get in touch with our expert team."
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Hero image={<HeroImage />} cta={<CTA />} />
    </Layout>
  );
};

export default SuccessPage;
